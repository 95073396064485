<template>
    <div class="container-inner">
        <div v-if="has_feedback == 1" class="thanks-zone d-flex flex-column justify-content-center">
            <div class="card">
                <div class="card-title py-5 text-center">
                    <h1>{{ $t('feedback.thanks') }}</h1>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ knownMethodOpt.label }}</label>
                <div class="form-check form-check-inline mx-2" v-for="(op, j) in knownMethodOpt.options" :key="j">
                    <input
                        class="form-check-input my-2"
                        type="radio"
                        name="known_method"
                        :id="'known_method' + op.value"
                        v-model="known_method"
                        :value="op.value"
                    />
                    <label class="form-check-label" :for="'known_method' + op.value">{{ op.label }}</label>
                </div>
                <input
                    v-if="known_method === 'other'"
                    class="form-control form-control-inline my-2"
                    type="text"
                    v-model="known_method_other"
                />
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ firstTimeOpt.label }}</label>
                <div class="form-check form-check-inline mx-2" v-for="(op, j) in firstTimeOpt.options" :key="j">
                    <input
                        class="form-check-input my-2"
                        type="radio"
                        name="first_time"
                        :id="'known_method' + op.value"
                        v-model="first_time"
                        :value="op.value"
                    />
                    <label class="form-check-label" :for="'first_time' + op.value">{{ op.label }}</label>
                </div>
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ foodStarOpt.label }}</label>
                <div class="form-check form-check-inline mx-2" v-for="(op, j) in foodStarOpt.options" :key="j">
                    <input
                        class="form-check-input my-2"
                        type="radio"
                        name="food_star"
                        :id="'food_star' + op.value"
                        v-model="food_star"
                        :value="op.value"
                    />
                    <label class="form-check-label" :for="'food_star' + op.value">{{ op.label }}</label>
                </div>
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ serviceStarOpt.label }}</label>
                <div class="form-check form-check-inline mx-2" v-for="(op, j) in serviceStarOpt.options" :key="j">
                    <input
                        class="form-check-input my-2"
                        type="radio"
                        name="service_star"
                        :id="'service_star' + op.value"
                        v-model="service_star"
                        :value="op.value"
                    />
                    <label class="form-check-label" :for="'service_star' + op.value">{{ op.label }}</label>
                </div>
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ haveDescOpt.label }}</label>
                <div class="form-check form-check-inline mx-2" v-for="(op, j) in haveDescOpt.options" :key="j">
                    <input
                        class="form-check-input my-2"
                        type="radio"
                        name="have_desc"
                        :id="'have_desc' + op.value"
                        v-model="have_desc"
                        :value="op.value"
                    />
                    <label class="form-check-label" :for="'have_desc' + op.value">{{ op.label }}</label>
                </div>
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ serveSpeedOpt.label }}</label>
                <div class="form-check form-check-inline mx-2" v-for="(op, j) in serveSpeedOpt.options" :key="j">
                    <input
                        class="form-check-input my-2"
                        type="radio"
                        name="serve_speed"
                        :id="'serve_speed' + op.value"
                        v-model="serve_speed"
                        :value="op.value"
                    />
                    <label class="form-check-label" :for="'serve_speed' + op.value">{{ op.label }}</label>
                </div>
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ $t('feedback.favorite') }}</label>
                <input class="form-control my-2" type="text" v-model="favorite_food" />
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ visitFrequencyOpt.label }}</label>
                <div class="form-check form-check-inline mx-2" v-for="(op, j) in visitFrequencyOpt.options" :key="j">
                    <input
                        class="form-check-input my-2"
                        type="radio"
                        name="visit_frequency"
                        :id="'visit_frequency' + op.value"
                        v-model="visit_frequency"
                        :value="op.value"
                    />
                    <label class="form-check-label" :for="'visit_frequency' + op.value">{{ op.label }}</label>
                </div>
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ $t('feedback.any_suggestion') }}</label>
                <textarea class="form-control" type="text" v-model="suggestion" />
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ $t('feedback.your_birthday') }}</label>
                <input class="form-control my-2" type="text" v-model="birthday" />
            </div>
            <div class="form-group my-3">
                <label class="d-block mb-2">{{ $t('feedback.phone') }}</label>
                <input class="form-control my-2" type="text" v-model="tel" />
            </div>

            <a class="cta style1" data-toggle="modal" @click="do_submit" data-target="#exampleModalCenter">{{
                $t('common.submit')
            }}</a>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.thanks-zone {
    height: calc(100vh - 200px);
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'Feedback',
    data() {
        return {
            known_method: '',
            known_method_other: '',
            first_time: '',
            food_star: '',
            service_star: '',
            suggestion: '',
            birthday: '',
            tel: '',
            have_desc: '',
            serve_speed: '',
            favorite_food: '',
            visit_frequency: ''
        };
    },
    created() {
        let _title = this.$t('feedback.feedBack');
        if (this.has_feedback == 1) {
            _title += '(Submitted)';
        }
        this.set_app_title(_title);

        this.init_data({
            sid: this.$store.state.app.sid,
            tid: this.$store.state.app.shared_tid,
            uid: this.$store.state.app.uid
        });
    },
    computed: {
        ...mapGetters({
            has_feedback: 'feedback/get_if_has_feedback',
            language: 'app/get_language'
        }),
        knownMethodOpt() {
            return {
                label: this.$t('feedback.how_you_know'),
                options: [
                    {
                        value: 'wx',
                        label: this.$t('feedback.wechat')
                    },
                    {
                        value: 'yelp',
                        label: "Yelp"
                    },
                    {
                        value: 'ins',
                        label: 'Instagram'
                    },
                    {
                        value: 'google',
                        label: 'Google'
                    },
                    {
                        value: 'friend',
                        label: this.$t('feedback.friends')
                    },
                    {
                        value: 'passby',
                        label: this.$t('feedback.walkingIn')
                    },
                    {
                        value: 'other',
                        label: this.$t('feedback.other')
                    }
                ]
            };
        },
        firstTimeOpt() {
            return {
                label: this.$t('feedback.is_first_time'),
                options: [
                    {
                        value: '1',
                        label: this.$t('feedback.yes')
                    },
                    {
                        value: '0',
                        label: this.$t('feedback.no')
                    }
                ]
            };
        },
        foodStarOpt() {
            return {
                label: this.$t('feedback.how_like_food'),
                options: [
                    {
                        value: '3',
                        label: this.$t('feedback.amazing')
                    },
                    {
                        value: '2',
                        label: this.$t('feedback.it_is_ok')
                    },
                    {
                        value: '1',
                        label: this.$t('feedback.not_fit')
                    }
                ]
            };
        },
        serviceStarOpt() {
            return {
                label: this.$t('feedback.if_like_service'),
                options: [
                    {
                        value: '3',
                        label: this.$t('feedback.great')
                    },
                    {
                        value: '2',
                        label: this.$t('feedback.good')
                    },
                    {
                        value: '1',
                        label: this.$t('feedback.no_good')
                    }
                ]
            };
        },
        haveDescOpt() {
            return {
                label: this.$t('feedback.if_introduced_food'),
                options: [
                    {
                        value: '1',
                        label: this.$t('feedback.introduced')
                    },
                    {
                        value: '0',
                        label: this.$t('feedback.no_introduced')
                    }
                ]
            };
        },
        serveSpeedOpt() {
            return {
                label: this.$t('feedback.how_fast_serving'),
                options: [
                    {
                        value: '3',
                        label: this.$t('feedback.serving_fast')
                    },
                    {
                        value: '2',
                        label: this.$t('feedback.serving_ok')
                    },
                    {
                        value: '1',
                        label: this.$t('feedback.serving_slow')
                    }
                ]
            };
        },
        visitFrequencyOpt() {
            return {
                label: this.$t('feedback.how_long_come'),
                options: [
                    {
                        value: '4',
                        label: this.$t('feedback.weekly')
                    },
                    {
                        value: '3',
                        label: this.$t('feedback.monthly')
                    },
                    {
                        value: '2',
                        label: this.$t('feedback.month_2-3')
                    },
                    {
                        value: '1',
                        label: this.$t('feedback.half_yearly')
                    }
                ]
            };
        }
    },
    watch: {
        has_feedback() {
            if (this.has_feedback == 1) {
                this.set_app_title('Feedback (Submitted)');
            }
        }
    },
    methods: {
        do_submit() {
            const post_data = {
                known_method: this.known_method,
                known_method_other: this.known_method_other,
                first_time: this.first_time,
                food_star: this.food_star,
                service_star: this.service_star,
                suggestion: this.suggestion,
                birthday: this.birthday,
                tel: this.tel,
                sid: this.$store.state.app.sid,
                tid: this.$store.state.app.shared_tid,
                uid: this.$store.state.app.uid,
                order_type: this.$store.state.app.type,
                tname: this.$store.state.app.shared_tname,
                have_desc: this.have_desc,
                serve_speed: this.serve_speed,
                favorite_food: this.favorite_food,
                visit_frequency: this.visit_frequency,
                router: this.$router
            };

            this.do_save(post_data);
            setTimeout(() => {
                this.known_method = '';
                this.known_method_other = '';
                this.first_time = '';
                this.food_star = '';
                this.service_star = '';
                this.suggestion = '';
                this.birthday = '';
                this.tel = '';
                this.have_desc = '';
                this.serve_speed = '';
                this.favorite_food = '';
                this.visit_frequency = '';
            }, 500);
        },
        ...mapActions({
            init_data: 'feedback/initData',
            do_save: 'feedback/doSave',
            set_msg: 'app/setToast'
        }),
        ...mapMutations({
            set_app_title: 'app/APP_SET_APP_TITLE'
        })
    }
};
</script>
